<template>
  <v-row class="joy-commitment-list dyp-card">
    <div class="scroll" style="max-height: 650px;">
      <v-col cols="12" class="joy-item text-left" v-for="item in joyCommitments" :key="item.id">
        <v-row>
          <v-col cols="10">
            <h4>{{ item.name }}</h4>
            <p>{{ item.text }}</p>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn class="joy-vote" dark depressed icon rounded :disabled="alreadyVoted(item, session.id)" color="gray" @click="upvote(item.id)">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <span class="vote-count" v-if="item.joy_submission_votes">{{ item.joy_submission_votes.length }}</span>
            <span class="vote-count" v-if="!item.joy_submission_votes">0</span>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'JoyCommitmentList',

  computed: {
    ...mapGetters({
      joyCommitments: 'joy-submission/list'
    }),

    session() {
      return this.$store.state.loginSession
    }
  },

  methods: {
    upvote(id) {
      this.$store.dispatch('joy-submission-vote/create', {
        participantId: this.session.id,
        joySubmissionId: id,
        vote: 1,
      }).then(() => {
        this.$store.dispatch('joy-submission/get', id)
      })
    },
    alreadyVoted(item, id) {
      const index = item.joy_submission_votes.map(e => e.participantId).indexOf(id)
      return (index > -1)
    }
  },

  beforeMount() {
    this.$store.dispatch('joy-submission/find', {
      query: {
        approved: true,
        random: true,
        $limit: 50,
      }
    });
  }
}
</script>

<style lang="scss">
.joy-commitment-list {
  padding: 10px;
}

.scroll {
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 5px;

    /* ===== Scrollbar CSS ===== */
  /* Firefox */
    & {
      scrollbar-width: auto;
      scrollbar-color: #FCE4B0 #ffffff;
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FCE4B0;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }

.joy-item {
  width: 100%;
  background-color: #FFF9EB;
  border-radius: 8px;
  margin-top: 4px;
}

.joy-item:first-child {
  margin-top: 0;
}

.joy-vote {
  background-color: #9C9C9C;
}

.joy-vote.v-btn--disabled {
  background-color: #37B3B1;

  .v-icon {
    color: #ffffff;
  }
}

.vote-count {
  display: block;
  margin-top: 6px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #9C9C9C;
}
</style>