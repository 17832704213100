<template>
  <div class="dashboard">
    <v-row align="center">
      <v-spacer />
      <v-col>
        <span class="mr-5">{{ session.firstName }} {{ session.lastName.charAt(0).toUpperCase() }}.</span>
        <v-btn @click="logout" small dark rounded color="#84C9C8">Logout</v-btn>
      </v-col>
    </v-row>
    <v-row class="timer-bar">
      <v-col>
        <p>DYP Challenge Ends In {{ timerText }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>My Donation</h1>
      </v-col>
    </v-row>
    <v-row class="dyp-card">
      <v-col cols="12">
        <h2>{{ session.charity.name }}</h2>
      </v-col>
      <v-row justify="center" align="center">
        <v-col cols="4">
          <p>Your Donation</p>
          <h1>${{ session.donationAmount.toFixed(2) }}</h1>
        </v-col>
        <v-col cols="2">
          <v-icon style="font-weight: 700;" color="#37B3B1" large>
            mdi-arrow-right
          </v-icon>
        </v-col>
        <v-col cols="4">
          <p>Total Donation*</p>
          <h1>${{ donationTotal }}</h1>
        </v-col>
        <v-col cols="12">
          <p>*If your Practice Commitment is completed</p>
        </v-col>
      </v-row>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>My Practice Commitment</h1>
      </v-col>
    </v-row>

    <v-row justify="center" class="dyp-card px-5 py-8">
      <v-col cols="12" v-if="session.livemettaId">
        <v-row justify="center" class="pb-5">
          <v-col cols="8">
            <v-img src="/livemetta-logo.svg" />
          </v-col>
        </v-row>
        <v-progress-linear
          rounded
          :value="livemettaProgress"
          color="#37B3B1"
          height="16"
        />
        <div class="legend-bar">
          <div class="start">0</div>
          <div class="mid">{{ pledgeCountHalfLivemetta }}</div>
          <div class="end">
            <span class="count">{{ session.pledgeCountLivemetta }}</span>
            <span class="label">Classes</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mb-6" v-if="!session.livemettaId">
        <a class="promo-btn" href="https://mailchi.mp/livemetta/summer-of-joy">Get an exclusive DYP offer from LiveMetta</a>
      </v-col>

      <v-col cols="12" v-if="session.rayogaId">
        <v-row justify="center" class="pb-5">
          <v-col cols="8">
            <v-img src="/rayoga-logo-brown.svg" />
          </v-col>
        </v-row>
        <v-progress-linear
          rounded
          :value="rayogaProgress"
          color="#37B3B1"
          height="16"
        />
        <div class="legend-bar">
          <div class="start">0</div>
          <div class="mid">{{ pledgeCountHalfRayoga }}</div>
          <div class="end">
            <span class="count">{{ session.pledgeCountRayoga }}</span>
            <span class="label">Classes</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mb-6" v-if="!session.rayogaId">
        <a class="promo-btn" href="https://landing.rayoga.com/?promo_id=26">Get an exclusive DYP offer from Ra Yoga</a>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h1>My Joy Commitment</h1>
      </v-col>
    </v-row>

    <v-row class="dyp-card text-left" v-if="session.joy_submission">
      <v-col cols="10">
        <h4>{{ session.joy_submission.name }}</h4>
        <p>{{ session.joy_submission.text }}</p>
      </v-col>
      <v-col cols="2" class="text-center">
        <v-btn class="joy-vote" dark depressed icon rounded :disabled="alreadyVoted(session.joy_submission, session.id)" color="gray" @click="upvote(session.joy_submission.id)">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <span class="vote-count">{{ session.joy_submission.joy_submission_votes.length }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h1>Our Community Commitments</h1>
      </v-col>
    </v-row>

    <joy-commitment-list />

    <v-row v-if="session.service_project">
      <v-col>
        <h1>My Service Commitment</h1>
      </v-col>
    </v-row>

    <v-row class="dyp-card text-left" v-if="session.service_project">
      <v-col>
        <h3>Donation Collection for OC &amp; LA Rescue Mission</h3>
        <p class="mb-2"><strong>Date: </strong>Aug 1st - Aug 20th 2023</p>
        <p><strong>Location: </strong>Any LIVEMETTA or Ra Yoga location</p>
        <div class="text-center">
          <p>You are bringing:</p>
          <h3 class="mb-2">{{ session.service_project.text }}</h3>
        </div>
      </v-col>
    </v-row>

    <div class="share-button">
      <a :href="shareLink" target="_blank">SHARE DYP</a>
    </div>
  </div>
</template>

<script>
import JoyCommitmentList from '@/components/JoyCommitmentList'

export default {
  name: 'Dashboard',

  components: {
    JoyCommitmentList,
  },

  data() {
    return {
      countDownDate: null,
      timerText: '',
      interval: null,
    }
  },

  computed: {
    session() {
      return this.$store.state.loginSession
    },
    isLoggedIn() {
      return this.session.id !== undefined
    },

    livemettaProgress() {
      return (this.session.classCountLivemetta / this.session.pledgeCountLivemetta) * 100
    },
    rayogaProgress() {
      return (this.session.classCountRayoga / this.session.pledgeCountRayoga) * 100
    },

    pledgeCountHalfLivemetta() {
      return this.session.pledgeCountLivemetta / 2
    },
    pledgeCountHalfRayoga() {
      return this.session.pledgeCountRayoga / 2
    },

    donationBonus() {
      let rayogaBonusModifier = 0
      let livemettaBonusModifier = 0

      switch(true) {
        case (this.session.pledgeCountRayoga > 0 && this.session.pledgeCountRayoga <= 10):
          rayogaBonusModifier = 0.5
          break;
        case (this.session.pledgeCountRayoga >= 11 && this.session.pledgeCountRayoga <= 24):
          rayogaBonusModifier = 0.75
          break;
        case (this.session.pledgeCountRayoga > 24):
          rayogaBonusModifier = 1
          break;
        default:
          rayogaBonusModifier = 0
          break;
      }

      switch(true) {
        case (this.session.pledgeCountLivemetta > 0 && this.session.pledgeCountLivemetta <= 10):
          livemettaBonusModifier = 0.5
          break;
        case (this.session.pledgeCountLivemetta >= 11 && this.session.pledgeCountLivemetta <= 24):
          livemettaBonusModifier = 0.75
          break;
        case (this.session.pledgeCountLivemetta > 24):
          livemettaBonusModifier = 1
          break;
        default:
          livemettaBonusModifier = 0
          break;
      }

      const rayogaBonus = this.session.donationAmount * rayogaBonusModifier
      const livemettaBonus = this.session.donationAmount * livemettaBonusModifier
      const bonus = (rayogaBonus + livemettaBonus).toFixed(2)

      return bonus
    },

    donationTotal() {
      return (parseFloat(this.session.donationAmount) + parseFloat(this.donationBonus)).toFixed(2)
    },

    participant() {
      return this.$store.state.participant.keyedById[this.$store.state.login.participantId]
    },

    shareLink() {
      let charityPrefix = ''
      switch(this.session.charityId) {
        case 1:
          charityPrefix = 'PP'
          break;
        case 2:
          charityPrefix = 'CA'
          break;
        case 3:
          charityPrefix = 'RM'
          break;
        case 4:
          charityPrefix = 'NA'
          break;
        case 5:
          charityPrefix = 'LG'
          break;
        case 6:
          charityPrefix = 'FS'
          break;
      }

      return `https://rayoga.com/app/uploads/dyp-share/${charityPrefix}-D${this.session.donationAmount}-${(this.session.pledgeCountLivemetta + this.session.pledgeCountRayoga)}.png`
    }
  },

  methods: {
    upvote(id) {
      this.$store.dispatch('joy-submission-vote/create', {
        participantId: this.session.id,
        joySubmissionId: id,
        vote: 1,
      }).then(() => {
        this.$store.dispatch('joy-submission/get', id)
      })
    },
    alreadyVoted(item, id) {
      const index = item.joy_submission_votes.map(e => e.participantId).indexOf(id)
      return (index > -1)
    },
    logout() {
      this.$store.state.loginSession = {}
      this.$router.push({ name: 'Login' })
    }
  },

  async beforeMount() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'Login' })
    } else {
      await this.$store.dispatch('participant/get', this.$store.state.login.participantId)
        .then(() => {
          this.$store.commit('setLoginSession', this.participant)

          if (!this.participant.hasPaid) {
            this.$router.push({ name: 'CompleteRegistration' })
          }
        })
    }

    this.countDownDate = new Date('2023-08-31T23:59:59').getTime()

    this.inverval = setInterval(function() {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = this.countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      //let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      this.timerText = `${days} days ${hours} hr ${minutes} min`

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(this.interval);
        this.timerText = "COMPLETED";
      }
      }.bind(this), 1000);
  }
}
</script>

<style lang="scss">
.share-button {
  position: fixed;
  left: -35px;
  top: 120px;

  a {
    display: block;
    height: 40px;
    padding: 10px 15px;
    font-size: 12px;
    background-color: #84C9C8;
    color: #ffffff;
    text-decoration: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    transform: rotate(90deg);
  }
}

.timer-bar {
  background-color: #F79387;
  margin-left: -42px;
  margin-right: -42px;

  p {
    color: #ffffff;
    margin-bottom: 0;
    font-weight: 700;
  }
}

.dyp-card {
  background-color: #FFFFFF;
  border: 2px solid #FCE4B0;
  border-radius: 8px;
  margin-bottom: 6px;
}

.legend-bar {
  position: relative;
  width: 100%;

  .start {
    width: 10%;
    float: left;
  }

  .mid {
    width: 90%;
    text-align: center;
  }

  .end {
    position: absolute;
    right: -10px;
    top: -30px;
    width: 44px;
    height: 44px;
    background-color: #37B3B1;
    border-radius: 100%;
    color: #ffffff;

    span {
      display: block;
    }

    .count {
      line-height: 1;
      font-size: 1.3em;
      padding-top: 7px;
      font-weight: 700;
    }

    .label {
      font-size: 8px;
      line-height: 1;
    }
  }
}

a.promo-btn {
  display: block;
  background-color: #fbb063;
  padding: 10px 20px;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.3);
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 700;
}

.vote-count {
  display: block;
  margin-top: 6px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #9C9C9C;
}
</style>